import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from './authentication.service';
import { User } from '../pages/user/user.model';
import { CustomValidators } from 'ng2-validation';
import { ToastrService, ToastrConfig } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToastrService]
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public email: AbstractControl;
  public password: AbstractControl;

  returnUrl: string;
  userlogin: User;
  currentUser: User;

  options: ToastrConfig;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private toastrService: ToastrService) {

    this.options = this.toastrService.toastrConfig;

    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, CustomValidators.email])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];

  }

  ngOnInit() {

    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      //this.authenticationService.deconnect(this.currentUser.utilisateurId)
      //.subscribe(
      //  data => {

      //  });
    }

    this.authenticationService.logout();

    let user = new User();
    this.userlogin = user;

    //this.userlogin = { utilisateurId:'', name:'', photo:'', password:'', email:'', disabled:false, connected:false, profiles:null, prf:null, id_intervenant: null };

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  public onSubmit(user: User): void {
    if (this.form.valid) {
      const success = (data: any) => {
        if (data.profil.code == 'ADMIN') {
          this.router.navigate(['pages/dashboard']);
        }
        if (data.profil.code == 'USER') {
          this.router.navigate(['pages/dossiers/liste']);
        }
        if (data.profil.code == 'GUEST') {
          this.router.navigate(['pages/dossiers/liste']);
        }
        if (data.profil.code == 'SGUEST') {
          this.router.navigate(['pages/dashboard']);
        }
      }
      const error = (err: any) => {
        const opt = JSON.parse(JSON.stringify(this.options));
        this.toastrService.error('Connexion impossible.\n Login ou mot de passe incorrect'.toUpperCase(), 'Erreur', opt);
      }
      this.authenticationService.login(user).subscribe(success, error);
    }
  }

}

export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}
