import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ConfigService {

   _apiURI : string;
   urlOrigine: string;
   constructor(@Inject(DOCUMENT) private document: Document) {
    //this._apiURI = 'http://192.168.5.2:8080/article-manager-0.0.1-SNAPSHOT/oeuvre/';
       this.urlOrigine = this.document.location.origin;
        // this._apiURI = 'http://localhost:8080/patrimoine/';

        this._apiURI = this.urlOrigine + '/patrimoinefoncier-0.0.1-SNAPSHOT/patrimoine/';
    }

    getApiURI() {
        return this._apiURI;
    }

    getApiHost() {
        return this._apiURI.replace('patrimoine/','');
    }
}
