import { Profil, Societe } from '../parametre/parametre.model';
import { PermissionBean } from './permissionbean.model';

export class User {
    utilisateurId: string;
    name: string;
    photo: string;
    password: string;
    email: string;
    disabled: boolean;
    profil: Profil;
    societe: Societe;
    permissions: PermissionBean[];
}



