import { Injectable } from "@angular/core";
import { ToastrConfig, ToastrService } from "ngx-toastr";

export class Settings {
    constructor(public name: string,
        public title: string,
        public theme: {
            menu: string,
            menuType: string,
            showMenu: boolean,
            navbarIsFixed: boolean,
            footerIsFixed: boolean,
            sidebarIsFixed: boolean,
            showSideChat: boolean,
            sideChatIsHoverable: boolean,
            skin: string
        }) { }
}

@Injectable()
export class HandleServiceError {
    public statusCode = 403;
    public options: ToastrConfig;
    public opt;
    constructor(private toastrService: ToastrService) {
        this.options = this.toastrService.toastrConfig;
        this.opt = JSON.parse(JSON.stringify(this.options));
    }
    erreurAjout = (error: any) => {
        if (error.status === this.statusCode) {
            const inserted = this.toastrService['error']('Accès non autorisé', 'Erreur', this.opt);
        } else {
            const inserted = this.toastrService['error']('Une erreur a été rencontré lors de l\'enregistrement.', 'Erreur', this.opt);
        }
    }
    erreurModification = (error: any) => {
        if (error.status === this.statusCode) {
            const inserted = this.toastrService['error']('Accès non autorisé', 'Erreur', this.opt);
        } else {
            const inserted = this.toastrService['error']('Une erreur a été rencontré lors de la modification.', 'Erreur', this.opt);
        }
    }
    erreurSuppression = (error: any) => {
        if (error.status === this.statusCode) {
            const inserted = this.toastrService['error']('Accès non autorisé', 'Erreur', this.opt);
        } else {
            const inserted = this.toastrService['error']('Suppresion impossible.', 'Erreur', this.opt);
        }
    }
}