import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
    public menu: Array<any>;
    public siteUser: Array<any>;

    constructor(private router: Router, private toastr: ToastrService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentUser')) {
            // logged in so return true
            this.menu = JSON.parse(sessionStorage.getItem('menuUser'));
            if (this.menu) {
                this.siteUser = JSON.parse(sessionStorage.getItem('siteUser'));
                let nbmenu = this.menu.filter(value => value.routerLink === state.url);

                let site = this.siteUser.filter((value) => {
                    var reg = new RegExp(value.routerLink);
                    return reg.test(state.url);
                });
                if (nbmenu.length > 0 || site.length > 0) {
                    return true;
                } else {
                    this.toastr.error('Accès refusé'.toUpperCase());
                    return false;
                }
            }
            return true;
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }
}