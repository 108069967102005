import { Injectable } from '@angular/core';
import { Predicate } from './interface';
import * as _ from 'alertify.js';


declare var alertify: any;


@Injectable()
export class NotificationService {

    

    private _notifier: any = alertify;
    

    constructor() {

        //this._notifier.set('notifier','position', 'bottom-right');
    }

    openConfirmationDialog(message: string, okCallback: () => any){
        this._notifier.confirm(message, function (e){
            if (e) {
                okCallback();
            } else {

            }
        });
    }
       /*
    Prints a success message using the alertify.js lib
    */
    printSuccessMessage(message: string) {
        
        //alertify.set('_notifier','position', 'bottom-right');
       
        this._notifier.success(message);
        
    }

       /*
    Prints an error message using the alertify.js lib
    */
    printErrorMessage(message: string) {
        this._notifier.error(message);
    }
}
