import { Injectable } from '@angular/core';
import { Http, Response, } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { User } from '../pages/user/user.model';
import { ConfigService } from '../shared/config.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import { timer } from 'rxjs/observable/timer';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switch';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { PermissionBean } from 'app/pages/user/permissionbean.model';

@Injectable()
export class AuthenticationService {
  _baseUrl: string = '';
  currentUser: User;

  private authState: AuthState;
  private authManager: BehaviorSubject<AuthState>;
  public authChange$: Observable<AuthState>;

  constructor(private http: Http,
    private router: Router,
    private configService: ConfigService) {
    this._baseUrl = configService.getApiURI();
    this.authManager = new BehaviorSubject(AuthState.LOGGED_OUT);
    this.authChange$ = this.authManager.asObservable();
    this.authChange$
      .filter((authState: AuthState) => authState === AuthState.LOGGED_IN)
      .map((authState: AuthState) => timer(SESSION_TIMEOUT))
      .do(() =>
        console.log('Logged In. Session Timeout counting down from now'))
      .switch()
      .subscribe(() => {
        console.log('Timer ended : Logging out')


        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (this.currentUser != null) {
        }

        this.logout();
        this.router.navigate(['/login']);


      });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  login(user: User): Observable<User> {

    return this.http.post(this._baseUrl + 'users/login', user)
      .map((response: Response) => {
        // login successful if there's a jwt token in the response
        let object = response.json();

        if (object) {
          sessionStorage.setItem('currentUser', JSON.stringify(object.data.utilisateurBean));
          this.setPermissions(object.data.utilisateurBean.permissions);
          this.setToken(object.data.token)
          this.setAuthState(AuthState.LOGGED_IN);
        }

        return object.data.utilisateurBean;
      });
  }

  setPermissions(permissions: Array<PermissionBean>) {
    if (permissions.length > 0) 
      sessionStorage.setItem('permissions', JSON.stringify(permissions.map(permission => permission.code)));
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  getToken(): any {
    return localStorage.getItem("access_token");
  }

  logout() {
    // remove user from local storage to log user out
    this.setAuthState(AuthState.LOGGED_OUT);

    sessionStorage.clear();
    localStorage.clear();

  }

  private setAuthState(newAuthState: AuthState): void {
    console.log('AuthService: setAuthState: ',
      AuthState[newAuthState.toString()]);
    if (newAuthState != this.authState) {
      this.authState = newAuthState;
      this.emitAuthState();
    }
  }

  emitAuthState(): void {
    this.authManager.next(this.authState);
  }


}

export enum AuthState {
  LOGGED_IN,
  LOGGED_OUT
}

const SESSION_TIMEOUT = 3600000;
